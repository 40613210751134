import React from "react"
import Navbar from "../components/App/Navbar"
import Layout from "../components/App/Layout"
import MainBanner from "../components/DataScienceAndMLCompany/MainBanner"
import OurSolutions from "../components/DataScienceAndMLCompany/OurSolutions"
import AboutUs from "../components/DataScienceAndMLCompany/AboutUs"
import Testimonials from "../components/DataScienceAndMLCompany/Testimonials"
import Faq from "../components/DataScienceAndMLCompany/Faq"
import StillOnTheFence from "../components/DataScienceAndMLCompany/StillOnTheFence"
import FunFacts from "../components/DataScienceAndMLCompany/FunFacts"

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <MainBanner />
      <OurSolutions />
      <AboutUs />
      <StillOnTheFence />
      <FunFacts />
      <Testimonials />
      <Faq />
    </Layout>
  )
}

export default Home